import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { FaEdit, FaTrash } from 'react-icons/fa';

import CustomTable from "../../components/common/CommonTable";
import {
  DeleteTrack,
  fetchAllTracks,
  fetchAllTracksByAlbumId,
} from "../../Api/helpers";

const TrackTableList = () => {
  const data = useParams();
  const navigate = useNavigate();
  const [tracks, setTracks] = useState([]);
  const handleEdit = (id, length) => {
    navigate(`/edit-track/${id}`, {state: {length: length}});
  };
  const handleButtonClick = () => {
    navigate(`/add-new-track`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeleteTrack(id);
      if (response?.data?.isSuccess) {
        setTracks((prevAlbums) =>
          prevAlbums?.filter((track) => track?.id !== id)
        );
        console.log(`Track with id ${id} deleted successfully`);
      } else {
        console.error("Error deleting track:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting track:", error);
    }
  };

  const fetchAlbums = async () => {
    try {
      if (data?.id) {
        const response = await fetchAllTracksByAlbumId(data?.id);
        if (response?.data?.isSuccess) {
          const albumsTableData = response?.data?.data.map((track, index) => ({
            "#": index + 1,
            Name: track?.title,
            Description: track?.description,
            Duration: track?.duration,
            Track: track?.file_path,
            Order: track?.order,
            Author: track?.author?.name,
            Album: track?.album?.title,
            Image: track?.image,
            id: track?.id,
          }));
          setTracks(albumsTableData);
        } else {
          console.error("Failed to fetch tracks:", response?.data?.message);
        }
      } else {
        const response = await fetchAllTracks();
        if (response?.data?.isSuccess) {
          const albumsTableData = response?.data?.data.map((track, index) => ({
            "#": index + 1,
            Name: track?.title,
            Description: track?.description,
            Duration: track?.duration,
            Track: track?.file_path,
            Order: track?.order,
            Author: track?.author?.name,
            Album: track?.album?.title,
            Image: track?.image,
            id: track?.id,
            category_id: track?.category_id,
            Category: track?.category?.name
          }));
          setTracks(albumsTableData);
        } else {
          console.error("Failed to fetch tracks:", response?.data?.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAlbums();
  }, []);
  const tableHeaders = [
    "#",
    "Name",
    "Description",
    // "Duration",
    "Track",
    "Category",
    "Order",
    "Image",
    "Author",
  ];
  if (data.id) {
    tableHeaders.push("Album");
  }
  console.log("123456789      ", tracks);
  //   { pageTitle, buttonText, cardHeader, data, tableHeaders, handleDelete, handleEdit, handleButtonClick }
  return (
    <CustomTable
      pageTitle={"Tracks"}
      buttonText={"Add New Tracks"}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      cardHeader={"Track List"}
      data={tracks}
      tableHeaders={tableHeaders}
      handleButtonClick={handleButtonClick}
    />
  );
};

export default TrackTableList;
